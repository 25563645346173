import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../../SuperAgent/CRM/Modal/CustomerLoder";
import moment from "moment";

var jwt = require("jsonwebtoken");

function AdminSavingTransactions({ hadleradmin }) {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");

  const [total, settotal] = useState([]);
  const [SuperAgentList, setSuperAgentList] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;

  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;

  const AddFormData = async (e, page) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data?.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);
    axios
      .post(`/v1/super-Agent/get-admin-loan-transaction`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        setData(data?.data);

        const total = resp.data?.counts;
        console.log(total);
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  const AdminSavingTransactions = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-admin-loan-transaction`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data;
        setData(data?.data);

        const total = resp.data?.counts;
        console.log(total);
        settotal(total);
        console.log("saving History", data);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };
  const AllSuperAgentData = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-admin-superagent-list`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data;

        setSuperAgentList(data?.data);
      });
  };
  useEffect(() => {
    AllSuperAgentData();
    AdminSavingTransactions();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    setShowLoader(true);
    const senData = { page: page };
    axios
      .post(`/v1/super-Agent/get-admin-loan-transaction`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        setData(data?.data);

        const total = resp.data?.counts;
        console.log(total);
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data?.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  useEffect(() => {
    hadleradmin();
  }, []);

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Transactions
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <div className="d-lg-flex align-lg-items-center">
                        <form onSubmit={(e) => AddFormData(e)}>
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              {/* <div className="col-lg-3 col-sm-3 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  className="form-select border-0 flex-grow-1"
                                  name="superAgentId"
                                  data-control="select2"
                                  data-placeholder="Category"
                                  data-hide-search="true"
                                >
                                  <option defaultValue="" selected disabled>
                                    Super Agent Id
                                  </option>

                                  {SuperAgentList?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                      {item._id}
                                    </option>
                                  ))}
                                </select>
                              </div> */}
                              {/* <div className="col-lg-3 col-sm-3 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  className="form-select border-0 flex-grow-1"
                                  name="savingType"
                                  data-control="select2"
                                  data-placeholder="Category"
                                  data-hide-search="true"
                                >
                                  <option defaultValue="" selected disabled>
                                  Saving Type
                                  </option>
                                  <option value={'volantary'} >
                                  Volantary
                                  </option>
                                  <option value={'mandatory'} >
                                  Mandatory
                                  </option>

                                </select>
                              </div> */}
                              <div className="col-lg-3 col-sm-3 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  className="form-select border-0 flex-grow-1"
                                  name="type"
                                  data-control="select2"
                                  data-placeholder="Category"
                                  data-hide-search="true"
                                >
                                  <option defaultValue="" selected disabled>
                                  Transaction Type
                                  </option>
                                  <option value={'saving'} >
                                  Savings
                                  </option>
                                  <option value={'share'} >
                                  Shares
                                  </option>
                                  <option value={'loan'} >
                                  Loans
                                  </option>
                                  <option value={'store'} >
                                  Store
                                  </option>

                                </select>
                              </div>
                              {/* <div className="col-lg-3 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-phone"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="search"
                                  placeholder="Search... "
                                />
                              </div> */}
                              <div className="col-lg-3 col-sm-3 d-flex input-container align-items-center mb-3  mb-lg-0">
                                <label className="small_label">
                                  Start Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="fromDate"
                                  placeholder="from Date.."
                                />
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              </div>

                              <div className="col-lg-3 col-sm-3 d-flex align-items-center mb-3 input-container mb-lg-0">
                                <label className="small_label">End Date</label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="toDate"
                                  placeholder="to Date.."
                                />
                              </div>
                            </div>

                            <div className="min-w-175px  gap-1 gap-sm-5 d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={AdminSavingTransactions}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Saving Transactions
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Transactions
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table
                        className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-300px">Transaction Id</th>

                            <th className="min-w-150px">Amount</th>
                            <th className="min-w-150px"> Type</th>
                            <th className="min-w-150px">Customer Name</th>
                            <th className="min-w-150px">Date</th>

                         
                          </tr>
                        </thead>

                        <tbody className="text-center">
                          {data?.map((item) => (
                            <tr>
                              {/* <td>
                                {" "}
                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-45px me-5">
                                    <img src={item?.custId?.IDphoto} alt="" />
                                  </div>
                                  <div class="d-flex justify-content-start flex-column">
                                    <a
                                      href={`/customer-view-admin/${item._id}`}
                                      class="usertitle Acolor mb-2"
                                    >
                                      {" "}
                                      {item?.custId?.fullname}
                                    </a>
                                    <span class="font-icon mb-1">
                                      <span class="">
                                        <i class="fal fa-phone-volume"></i>
                                      </span>
                                      +{item?.custId?.phone}
                                    </span>

                                    <span class="font-icon mb-1">
                                      <span class="">
                                        <i class="fal fa-envelope"></i>
                                      </span>
                                      {item?.custId?.email}{" "}
                                    </span>
                                  </div>
                                </div>
                              </td> */}

                              <td>####{item?.transactionId?.slice(17)}</td>
                              <td>{item?.amount}</td>
                              <td>{item?.transactionType}</td>
                              <td>{item?.name}</td>

                              {moment(item?.date).format("DD/MM/YYYY")}
                              <td>{item?.savingType}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-1 align-items-center"
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminSavingTransactions;
