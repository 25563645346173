import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { error } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import CustomerLoader from "../CRM/Modal/CustomerLoder";
function SuperAgentAddCustomer({ hadlersuper }) {
  let token = localStorage.getItem("SuperAgentChromepayToken");

  // console.log("token"+token)
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [customer, setCutsmerImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [isMarried, setMarried] = useState(null);
  const [phoneno, setPhoneNo] = useState("");
  const shippingStages = ["Profile", "Address", "Document"];
  const [ShowLoader, setShowLoader] = useState(false);

  const educationOptions = [
    { value: "Graduate", label: "Graduate" },
    { value: "Under-Graduate", label: "Under-Graduate" },
    { value: "School", label: "School" },
    { value: "None", label: "None" },
  ];

  const phoneCode = [
    { value: "Graduate", label: "Graduate" },
    { value: "Under-Graduate", label: "Under-Graduate" },
    { value: "School", label: "School" },
    { value: "None", label: "None" },
  ];

  const EthiopianCities = [
    { value: "addis-ababa", label: "Addis Ababa" },
    { value: "gode", label: "Godē" },
    { value: "erer-sata", label: "Ērer Sātā" },
    { value: "nazret", label: "Nazrēt" },
    { value: "gondar", label: "Gonder" },
    { value: "mekele", label: "Mekele" },
    { value: "awasa", label: "Āwasa" },
    { value: "dire-dawa", label: "Dire Dawa" },
    { value: "bahir-dar", label: "Bahir Dar" },
    { value: "shashemene", label: "Shashemenē" },
    { value: "sodo", label: "Sodo" },
    { value: "arba-minch", label: "Ārba Minch" },
    { value: "dese", label: "Desē" },
    { value: "hosaina", label: "Hosaina" },
    { value: "jima", label: "Jīma" },
    { value: "harar", label: "Harar" },
    { value: "jijiga", label: "Jijiga" },
    { value: "dila", label: "Dīla" },
    { value: "nekemte", label: "Nekemtē" },
    { value: "debre-birhan", label: "Debre Birhan" },
    { value: "debre-markos", label: "Debre Markos" },
    { value: "ferfer", label: "Ferfer" },
    { value: "aware", label: "Āwarē" },
    { value: "kombolcha", label: "Kombolcha" },
    { value: "debre-tabor", label: "Debre Tabor" },
    { value: "adigrat", label: "Ādīgrat" },
    { value: "giyon", label: "Giyon" },
    { value: "asela", label: "Āsela" },
    { value: "kebri-dehar", label: "Kebrī Dehar" },
    { value: "debre-zeyit", label: "Debre Zeyit" },
    { value: "hagere-hiywet", label: "Hāgere Hiywet" },
    { value: "aksum", label: "Āksum" },
    { value: "boditi", label: "Bodītī" },
    { value: "finote-selam", label: "Finote Selam" },
    { value: "semara", label: "Semera" },
    { value: "goba", label: "Goba" },
    { value: "yirga-alem", label: "YirgaAlem" },
    { value: "adwa", label: "Ādwa" },
    { value: "gambela", label: "Gambēla" },
    { value: "bedesa", label: "Bedēsa" },
    { value: "azezo", label: "Āzezo" },
    { value: "gimbi", label: "Gīmbī" },
    { value: "areka", label: "Āreka" },
    { value: "asosa", label: "Āsosa" },
    { value: "wikro", label: "Wikro" },
    { value: "welkite", label: "Welkītē" },
    { value: "metu", label: "Metu" },
    { value: "fiche", label: "Fichē" },
    { value: "kolito", label: "Kolīto" },
    { value: "genet", label: "Genet" },
    { value: "agaro", label: "Āgaro" },
    { value: "gelemso", label: "Gelemso" },
    { value: "maychew", label: "Maychew" },
    { value: "mizan-teferi", label: "Mīzan Teferī" },
    { value: "sekota", label: "Sekota" },
    { value: "himora", label: "Himora" },
    { value: "bonga", label: "Bonga" },
    { value: "bichena", label: "Bichena" },
    { value: "bedele", label: "Bedelē" },
    { value: "kemise", label: "Kemisē" },
    { value: "bedesa", label: "Bedēsa" },
    { value: "cheraro", label: "Cheraro" },
    { value: "huruta", label: "Huruta" },
    { value: "korem", label: "Korem" },
    { value: "hirna", label: "Hīrna" },
    { value: "gesuba", label: "Gesuba" },
    { value: "kofele", label: "Kofelē" },
    { value: "kwihā", label: "Kwīhā" },
    { value: "sendafa", label: "Sendafa" },
    { value: "negelle", label: "Negēlē" },
    { value: "chencha", label: "Chencha" },
    { value: "hagere-selam", label: "Hāgere Selam" },
    { value: "indibir", label: "Indibir" },
    { value: "ejersa-goro", label: "Ējersa Goro" },
    { value: "yetman", label: "Yetman" },
  ];

  const handleFileChange = (data) => {
    let formdata = new FormData();
    // console.log("@#@#@123" + data);
    setShowLoader(true)
    if (data) {
      const url = URL.createObjectURL(data);
      console.log("@#@#@" + url);
      setImageUrl(url);
      formdata.append("file", data);

      console.log("image first data", formdata);

      formdata.append("Image", data);

      console.log("image first data", formdata);
      axios
        .post(`/v1/DID/ImageUploader`, formdata, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const imgurl = res.data.data;
          console.log("image url", imgurl);
          setCutsmerImage(imgurl);
    setShowLoader(false)

        })
        .catch((error) => {
          console.error("Error uploading image:", error);
    setShowLoader(false)

        });
    } else {
      toast.error(data.msg);
    }
    // })
    // .catch(error => {
    //   console.error("Error checking face API:", error);
    // });
    // } else {

    // }
  };

  const [mobileNumber, setMobileNumber] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [smsstatus, setsmsstatus] = useState();

  const handleMobileNumberChange = async (event) => {
    const token = localStorage.getItem("SuperAgentChromepayToken");

    const newMobileNumber = event.target.value;
    console.log("newMobileNumber" + newMobileNumber);
    setMobileNumber(newMobileNumber);
    axios
      .post(
        `/v1/DID/check-phone-number`,
        { phone: newMobileNumber },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        setsmsstatus(data.status);
        setIsRegistered(data.msg);
        if (!data.status) {
          console.log(data.msg);
          setIsRegistered(data.msg);
          // toast.success(data.msg)
        } else {
          console.log(data.msg);
          setIsRegistered(data.msg);
          // toast.error(data.msg)
        }
      });
  };

  const AddInfo = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    console.log(Formvlaues);

    let dataToSend2 = new FormData();
    dataToSend2.append("IDphoto", customer);

    let sendData = { ...dataToSend2, ...Formvlaues };

    localStorage.setItem("IDphoto", customer);
    localStorage.setItem("fullname", Formvlaues.fullname);
    localStorage.setItem("dateOfBirth", Formvlaues.dateOfBirth);
    localStorage.setItem("phone", Formvlaues.phone);
    localStorage.setItem("email", Formvlaues.email);
    localStorage.setItem("gender", Formvlaues.gender);
    localStorage.setItem("NextFOKinName", Formvlaues.NextFOKinName);
    localStorage.setItem("NextFOKniPhone", Formvlaues.NextFOKniPhone);

    Formvlaues.IDphoto = customer;
    navigate("/super/customer/address", {
      state: Formvlaues,
    });
  };

  const handleKeyUp = (e) => {
    console.log("phone", e.key);
    // const phone = e.key;
    // axios.post(`/v1/DID/check-phone-number`,{phone},{ headers: { "Authorization": `Bearer ${token}`}})
    // .then((resp)=>{
    //     const data = resp.data;
    //     console.log(data);
    //     if (data.status) {
    //         setPhoneNo(data.msg)
    //     //   toast.success(data.msg);
    //     } else {
    //       toast.error(data.msg);
    //     }
    // })
  };
  useEffect(() => {
    hadlersuper();
  }, []);

  return (
    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <ToastContainer />
        <CustomerLoader loader={ShowLoader} />

        <div class="container-xxl" id="kt_content_container">
          <div class="row g-5 g-xl-8">
            <div class="col-lg-12">
              <div class="card mb-5 mb-xl-10">
                <div
                  class="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_profile_details"
                  aria-expanded="true"
                  aria-controls="kt_account_profile_details"
                >
                  <div class="card-title m-0">
                    <h3 class="fw-bold m-0">Add Customer</h3>
                  </div>
                  {/* <div className="progress-bar1">
                                        {shippingStages.map((stage, index) => (
                                            <div
                                                key={index}
                                                className={`progress-stage1 ${currentStep === index + 1 ? 'active' : ''} ${currentStep > index + 1 ? 'completed' : ''}`}
                                            >
                                                {stage}
                                            </div>
                                        ))}
                                    </div> */}
                  <div class="my">
                    <div class="margin-area">
                      <div class="dot one">1</div>
                      <div class="dot two">2</div>
                      <div class="dot three">3</div>
                      {/* <div class="dot four">4</div> */}
                      <div class="progress-bar "></div>
                      <div class="progress-bar second"></div>
                      {/* <div class="progress-bar third"></div> */}
                      <div class="message message-1">Profile</div>`
                      <div class="message message-2">Address</div>
                      <div class="message message-3">Document</div>
                      {/* <div class="message message-4">Delivered</div> */}
                    </div>
                  </div>
                </div>

                <div
                  id="kt_account_settings_profile_details"
                  class="collapse show"
                >
                  <form onSubmit={AddInfo}>
                    <div class="card-body border-top p-9">
                      <div class="row mb-6">
                        <div className="col-lg-4">
                          <div className="row">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              ID Photo
                            </label>
                            <div className="col-lg-8">
                              <div
                                className="image-input image-input-outline"
                                data-kt-image-input="true"
                                style={{
                                  backgroundImage:
                                    "url('https://chromepay1.s3.ap-south-1.amazonaws.com/abc/avatar-659651_640%20%281.png)'",
                                }}
                              >
                                <div
                                  className="image-input-wrapper w-125px h-125px"
                                  style={{
                                    backgroundImage:
                                      "url('https://chromepay1.s3.ap-south-1.amazonaws.com/abc/avatar-659651_640%20%281.png')",
                                  }}
                                ></div>
                                <div
                                  style={{ position: "absolute", top: "0px" }}
                                >
                                  {imageUrl && (
                                    <img
                                      src={imageUrl}
                                      alt="Uploaded"
                                      className="w-125px h-125px"
                                    />
                                  )}
                                </div>
                                <label
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Upload Image"
                                >
                                  <i className="bi bi-pencil-fill fs-7"></i>
                                  <input
                                    type="file"
                                    name="IDphoto"
                                    onChange={(e) =>
                                      handleFileChange(e.target.files[0])
                                    }
                                  />
                                </label>
                                <span
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="cancel"
                                  data-bs-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i className="bi bi-x fs-2"></i>
                                </span>
                                <span
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i className="bi bi-x fs-2"></i>
                                </span>
                              </div>
                              <div className="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8">
                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label  fw-semibold fs-6">
                              Full Name
                            </label>
                            <div class="col-lg-8">
                              <div class="row">
                                <div class="col-lg-12 fv-row">
                                  <input
                                    type="text"
                                    name="fullname"
                                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                    placeholder="Full Name"
                                  />
                                </div>

                                {/* <div class="col-lg-6 fv-row">
                  <input type="text" name="lname" class="form-control form-control-lg form-control-solid" placeholder="Next Of Kin (Name)"  />
                </div> */}
                              </div>
                            </div>
                          </div>

                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Date of Birth
                            </label>

                            <div class="col-lg-8 fv-row">
                              <input
                                type="date"
                                name="dateOfBirth"
                                class="form-control form-control-lg form-control-solid position-relative"
                              />
                            </div>
                          </div>

                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              <span>Contact No</span>
                              <i
                                className="fas fa-exclamation-circle ms-1 fs-7"
                                data-bs-toggle="tooltip"
                                title="Phone number must be active"
                              ></i>
                            </label>
                            <div className="col-lg-8 fv-row">
                              <PhoneInput
                                country="et" // Set the default country code for Ethiopia
                                enableSearch={true}
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                  placeholder: "Enter phone number",
                                }}
                                containerClass="phone-input-container" // Add any additional classes if needed
                                inputClass="form-control form-control-lg form-control-solid" // Add any additional classes if needed
                                placeholder="Phone number"
                              />
                            </div>
                          </div>

                          <div class="row  mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Email Address
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="email"
                                name="email"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Email Address"
                              />
                            </div>
                          </div>

                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label  fw-semibold fs-6">
                              Gender
                            </label>
                            <div class="col-lg-8 fv-row">
                              <div class="d-flex align-items-center mt-3">
                                <label class="form-check form-check-inline form-check-solid me-5">
                                  <input
                                    class="form-check-input"
                                    name="gender"
                                    type="radio"
                                    value="Male"
                                  />
                                  <span class="fw-semibold ps-2 fs-6">
                                    Male
                                  </span>
                                </label>

                                <label class="form-check form-check-inline form-check-solid">
                                  <input
                                    class="form-check-input"
                                    name="gender"
                                    type="radio"
                                    value="Female"
                                  />
                                  <span class="fw-semibold ps-2 fs-6">
                                    Female
                                  </span>
                                </label>
                                <label class="form-check form-check-inline form-check-solid">
                                  <input
                                    class="form-check-input"
                                    name="gender"
                                    type="radio"
                                    value="Other"
                                  />
                                  <span class="fw-semibold ps-2 fs-6">
                                    Other
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Next Of Kin (Name)
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="NextFOKinName"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Next Of Kin (Name)"
                              />
                            </div>
                          </div>
                          <div class="row mb-6 ">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Next Of Kin Phone
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="number"
                                name="NextFOKniPhone"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Next Of Kin Phone"
                              />
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Place Of Birth
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="placeOfBirth"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Place Of Birth"
                              />
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Mother Name
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="motherName"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Mother Name"
                              />
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Street Address
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="streetAdress"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Street Address"
                              />
                            </div>
                          </div>
                          {/* <div class="row mb-6">
                                                        <label class="col-lg-4 col-form-label fw-semibold fs-6">Sub City</label>
                                                        <div class="col-lg-8 fv-row">
                                                            <input type="text" name="subCity" class="form-control form-control-lg form-control-solid" placeholder="Enter Sub City" />
                                                        </div>
                                                    </div> */}
                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              City
                            </label>
                            <div class="col-lg-8 fv-row">
                              <select
                                type="text"
                                name="City"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Education"
                              >
                                <option value="">Select an option</option>
                                {EthiopianCities.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              {" "}
                              Sub City
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="subCity"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Sub City"
                              />
                            </div>
                          </div>

                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Monthly Income
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="monthlyIncome"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Monthly Income"
                              />
                            </div>
                          </div>

                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Education
                            </label>
                            <div class="col-lg-8 fv-row">
                              <select
                                type="text"
                                name="education"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Education"
                              >
                                <option value="">Select an option</option>
                                {educationOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Marriage Status
                            </label>
                            <div class="col-lg-8 fv-row">
                              <select
                                type="text"
                                name="married"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Place Of Birth"
                                onChange={(e) => {
                                  const selectedValue = e.target.value;

                                  if (selectedValue === "married") {
                                    setMarried(selectedValue);
                                  } else {
                                    setMarried(null);
                                  }
                                }}
                              >
                                <option value="">Select an option</option>
                                <option value="single" label="Single">
                                  Select an option
                                </option>
                                <option value="married" label="Married">
                                  Select an option
                                </option>
                              </select>
                            </div>
                          </div>
                          {isMarried && (
                            <div class="row mb-6">
                              <label class="col-lg-4 col-form-label fw-semibold fs-6">
                                Spouse Name
                              </label>
                              <div class="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="spouseName"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="Enter Spouse Name"
                                />
                              </div>
                            </div>
                          )}
                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Land Address
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="landAddress"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Land Address"
                              />
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Property Address
                            </label>
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="propertyAddess"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Enter Property Address"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-semibold fs-6">Nationality</label>
            <div class="col-lg-8 fv-row">
              <input type="text" name="nationality" class="form-control form-control-lg form-control-solid" placeholder="Nationality"  />
            </div>                     
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-semibold fs-6">Profession</label>
            <div class="col-lg-8 fv-row">
              <input type="text" name="profession" class="form-control form-control-lg form-control-solid" placeholder="Profession"  />
            </div>                     
          </div>   */}

                      {/* <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-semibold fs-6">Address</label>
            <div class="col-lg-8 fv-row">
              <input type="text" name="website" class="form-control form-control-lg form-control-solid" placeholder="Address"  />
            </div>                     
          </div> */}
                    </div>
                    <div class="card-footer d-flex justify-content-end py-5 px-9">
                      {/* <button type="reset" class="btn btn-light btn-active-light-primary me-2">Reset</button> */}
                      <button
                        type="submit"
                        class="btn btn-primary"
                        id="kt_account_profile_details_submit"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
          <div class="container-xxl d-flex flex-column flex-md-row flex-stack">
            <div class="text-dark order-2 order-md-1">
              <span class="text-gray-400 fw-semibold me-1">Powered by</span>
              <a
                href="#"
                class="text-muted text-hover-primary fw-semibold me-2 fs-6"
              >
                Chromepay
              </a>
            </div>

            <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
              <li class="menu-item">
                <a href="#" class="menu-link px-2">
                  About
                </a>
              </li>
              <li class="menu-item">
                <a href="#" class="menu-link px-2">
                  Support
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperAgentAddCustomer;
