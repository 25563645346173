import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useNavigate } from "react-router-dom";
import CustomerLoader from "../CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");
export default function ProductManagementList({ hadlersuper }) {
  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");

  const [pageCount, setpageCount] = useState("");

  const [total, settotal] = useState([]);
  const [ShowPassword, setShowPassword] = useState(false);

  const [ProductDetails, setProductDetails] = useState([]);
  const [productID, setProductID] = useState("");

  const limit = 10;
  const [ShowLoader, setShowLoader] = useState(true);

  const ProductList = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-product`,
        { page: 1, limit: 10 },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        let filter = data.data;
        setProductDetails(filter?.list);

        const total = resp.data?.data?.count;
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);

        setShowLoader(false);
      });
  };

  const VeriflyAfterDelete = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;
      if (data.status) {
        handleDeleteProduct();
        setShowPassword(false);
      }
    } catch (error) {}
  };

  const fetchComments = async (page) => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-product`,

        { page: page, limit: 10 },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        let filter = data.data;
        setProductDetails(filter?.list);

        const total = resp.data?.data?.count;
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);

        setShowLoader(false);
      });
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  ///////////////pagenestion///////////////

  const formsave = (e, page) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(`/v1/super-Agent/get-product`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        let data = resp.data;
        let filter = data.data;
        setProductDetails(filter?.list);

        const total = resp.data?.data?.count;
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);

        setShowLoader(false);
      });
  };

  /////////////unblocked user api call ////////////

  /////////////blocked user api call ////////////

  useEffect(() => {
    hadlersuper();
    ProductList();
  }, []);

  const handleDeleteProduct = () => {
    let productId = productID;

    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/v1/super-Agent/product-delete`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { productId },
          })
          .then((response) => {
            if (response?.data?.status) {
              Swal.fire(
                "Deleted!",
                "Product has been deleted successfully",
                "success"
              );
              ProductList();
            }
          });
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Product List
                      </span>
                    </div>
                    <form onSubmit={(e) => formsave(e)}>
                      <div className="rounded d-flex flex-column col-md-5 flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          {/* <div className="col-lg-4 col-sm-4   d-flex input-container align-items-center mb-3  mb-lg-0">
                            <label className="small_label">Start Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="fromDate"
                              placeholder="from Date.."
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-lg-4 col-sm-4   d-flex align-items-center mb-3 input-container mb-lg-0">
                            <label className="small_label">End Date</label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <i className="fad fa-calendar fs-2"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1"
                              name="toDate"
                              placeholder="to Date.."
                            />
                          </div> */}

                          <div className="col-lg-6 col-sm-6 d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="17.0365"
                                  y="15.1223"
                                  width="8.15546"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 17.0365 15.1223)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="search"
                              placeholder="Search.."
                            />
                          </div>

                          <div className="col-lg-6 col-sm-6 d-flex align-items-center gap-1">
                            <button
                              type="submit"
                              className="btn btn-dark  mx-sm-3"
                              id="kt_advanced_search_button_1"
                            >
                              Search
                            </button>
                            <button
                              type="reset"
                              onClick={() => ProductList()}
                              className="btn btn-secondary"
                              id="kt_advanced_search_button_1"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Product List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Product
                      </span>
                    </h3>
                    <div class="card-title">
                      <button
                        onClick={() => navigate("/add/products")}
                        className="btn btn-light btn-active-light-primary me-2"
                      >
                        Add Product
                      </button>
                    </div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px text-center">
                              Product Photo
                            </th>
                            <th className="min-w-125px text-center">
                              Product Name
                            </th>
                            <th className="min-w-125px text-center">
                              Product Category
                            </th>
                            <th className="min-w-125px text-center">
                              description
                            </th>
                            <th className="min-w-125px text-center">
                              Inventory Left
                            </th>
                            <th className="min-w-125px text-center">Unit</th>
                            <th className="min-w-125px text-center">
                              Product Price Per
                            </th>
                            <th className="min-w-125px text-center">
                              Company Name
                            </th>
                            {/* <th className="min-w-125px">
                              Product Max Quantity
                            </th> */}

                            <th className="min-w-100px text-center text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {ProductDetails?.map((item) => (
                            <tr>
                              <td>
                                {" "}
                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-45px me-5">
                                    <img
                                      className="text-center"
                                      src={
                                        item?.image ||
                                        "/assets_new/images/lodingwallet.gif"
                                      }
                                      alt="product Image"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                {item?.productName}
                              </td>
                              <td className="text-center">{item?.category}</td>
                              <td
                                className="text-center"
                                style={{ width: "160px" }}
                              >
                                {item?.description}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  width: "160px",
                                  color:
                                    item?.availableQuantity < 10
                                      ? "red"
                                      : "green",
                                }}
                              >
                                {item?.availableQuantity}
                              </td>
                              <td className="text-center">
                                {item?.unit || "Not Available"}
                              </td>
                              <td className="text-center">
                                {item?.pricePerKg}
                              </td>
                              <td className="text-center">
                                {item?.companyName}
                              </td>

                              <td>
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  <button
                                    onClick={() => {
                                      navigate(
                                        `/update/products/${item?._id}`,
                                        { state: item }
                                      );
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Update Product"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fas fa-pencil-alt"></i>
                                    </span>
                                  </button>

                                  <button
                                    onClick={() => {
                                      setShowPassword(true);
                                      setProductID(item?._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete Product"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fas fa-trash-alt"></i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-2"
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-center"
        show={ShowPassword}
        onHide={() => setShowPassword(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VeriflyAfterDelete}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Verify
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
