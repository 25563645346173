import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { JWT } from "jsonwebtoken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertMessage from "./AlertMessage";
import axios from "axios";
import { error } from "jquery";
import Chart from "react-apexcharts";
import moment, { months } from "moment";
import { useNavigate } from "react-router-dom";
var jwt = require("jsonwebtoken");

export default function OrganizationDashboard({hadlerorg}) {

  const navigate = useNavigate()
   let token = localStorage.getItem("organizationToken");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;
  
  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [finduser, setFinduser] = useState([]);
  const [findTrans, setFindTrans] = useState([]);
  
  const [section, setSection] = useState([]);
  
  const [image, setimage] = useState("");
  const [Agents, setAgents] = useState("");
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);
  const [orgdash, setOrgDash] = useState([]);
  let users = section.totalUser;
  let transections = section.totalTransection;
  const [frequency, setFrequency] = useState("month")




// bank checking logic

const [checkbank, setcheckbank] = useState(true);
useEffect(() => {
  let orgAgentType = localStorage.getItem("orgAgentType");
  if (orgAgentType == "Union") {
    setcheckbank(false);
  } else if (orgAgentType == "Cooperative") {
    setcheckbank(false);
  } else {
    setcheckbank(true);
  }
}, []);


const formatNumber = (number) => {
  if (number >= 1e12) {
    return (number / 1e12).toFixed(1) + "T"; // Trillions
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + "B"; // Billions
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + "M"; // Millions
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + "k"; // Thousands
  } else {
    return number?.toString(); // Less than a thousand
  }
};

  // const logout = () => {
  //   localStorage.clear();
  //   window.location = `/`;
  // };
  // setTimeout(logout, 60 * 60 * 1000);

  const AddFormData = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.get(`/OrganisationDash/${orgID}`, formData).then((resp) => {
      let data = resp.data.filter;
      setData(data);
      console.log("OrganisationDash/:ID", data);
    });
  };

 
  const userlist = () => {
    axios.post(`/v1/org/dash/detail`,{},{ headers: {"Authorization" : `Bearer ${token}`} })
    .then((resp) => {
      let data = resp.data;
      const findLicenseFees = data.findLicenseFees;
      const finduser =data.finduser;
      const findTrans =data.findTrans;
      setData1(data);
      setOrgDash(findLicenseFees);
      setFinduser(finduser)
      setFindTrans(findTrans)
      console.log("@@@",data);
     //console.log("@@@",findLicenseFees);
      //console.log(data);
    });
  };

  
  

//   const UserPermoance = async () => {
//  await axios.post(`/v1/org/dash/get_org_cust_data_graph`,{},{headers:{"Authorization": `Bearer ${token}`}})
//     .then((res) => {
//       const userData = res.data.data;
 
//           console.log("++++++++++++++token", userData);

//       setuseractivedatayear(Object.values(userData));
//       setuseractivedatamonths(Object.keys(userData));
//     });
//   };
  const UserPermoance = () => {
    let type= frequency
    let body={Type:type}
    axios.post(`/v1/org/dash/get_org_cust_data_graph`,body,{ headers: {"Authorization" : `Bearer ${token}`}}).then((res) => {
      const userData = res.data.data;
      const year=userData.map((item)=>item.count)
      const month=userData.map((item)=>item.value)
       console.log("++++++++++++++graph data", year,month);

      setuseractivedatayear(year);
      setuseractivedatamonths(month);
    });
  };
  


  const custDetail = (_id) =>{
    // window.location = `/org-customer-view/${_id}`;
    navigate(`/org-customer-view/${_id}`)
    return false;

  }
  const transDetail = (_id) =>{
    // window.location = `/transaction-view-org/${_id}`;
    navigate(`/transaction-view-org/${_id}`)
    return false;

  }




  useEffect(() => {
    userlist();
    // UserPermoance();
    hadlerorg();
    
  }, []);
  useEffect(() => {
    UserPermoance();
  }, [frequency])
  
  const handlesorting = (event) => {
    let sort = event.target.value;
    setFrequency(sort);
    UserPermoance(sort);
    console.log(sort)

  }
  let mystyle = {
    border: "2px solid red",
  };

  return (
    <>
      <ToastContainer position="top-right" />


      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" >
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                {/* {data1.remaning_Licenses >= 15 ? "" : <AlertMessage />} */}
                {!data1.remaning_Licenses >= 15 ? <AlertMessage />:"" }

                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                            {section.name}
                          </span>
                          <div className="fs-4 text-white"></div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative dashorgnastion">
                          <div className="row g-3 g-lg-6">
                            <div className="col-6">
                              <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fas fa-id-card"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data1.findNoOfuser}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1.findNoOfuser}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                  Total Active {checkbank?"IDs":"Bruh IDs"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="retative bg-gray-100  animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data1.find_Agent}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1.find_Agent}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Number Of Agents
                                  </span>
                                </div>
                              </div>
                            </div>
{/* 
                            <div className="col-4">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-hands-usd"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data1.findNumOfTrans}
                                    className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1.findNumOfTrans}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Transactions{" "}
                                  </span>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className="col-4">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-landmark"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data1.findLoanApplication}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                   {data1.findLoanApplication}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                  Loan Applications
                                  </span>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-envelope-open-dollar"></i>
                                   
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={`$${orgdash.recuuringFees}`}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`$${orgdash.recuuringFees}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Recurring Fees{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            {!data1.remaning_Licenses >= 15 ? (
                              <div className="col-6">
                                <Link to="/remaning-license">
                                  <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5"
                                    style={mystyle}>
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-landmark"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="m-0">
                                      <span
                                        // data-kt-countup="true"
                                        // data-kt-countup-value={
                                        //   formatNumber( data1.remaning_Licenses)
                                        // }
                                        className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {formatNumber( data1.remaning_Licenses)}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                      Remaining {checkbank?"IDs":"Bruh IDs"} Licences{" "}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <div className="col-6">
                                <Link to="/remaning-license">
                                  <div
                                    className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5"
                                  
                                  >
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-landmark"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="m-0">
                                      <span
                                        data-kt-countup="true"
                                        data-kt-countup-value={
                                          data1.remaning_Licenses
                                        }
                                        className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {data1.remaning_Licenses}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                      Remaining {checkbank?"IDs":"Bruh IDs"} Licences{" "}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            )}



<div className="col-6">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-sack-dollar"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={Math.round(
                                      parseFloat(data1.totalSales)
                                    )}
                                    data-kt-countup-prefix=""
                                    className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                 
                                      
                                 {parseFloat(data1.totalSales)}
                            
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Sales (ETB)
                                  </span>
                                </div>
                              </div>
                            </div>




                            {/* manage savings */}

                            <div className="col-6">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-sack-dollar"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={Math.round(
                                      parseFloat(data1?.totalSavingAmount)
                                    )}
                                    data-kt-countup-prefix=""
                                    className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                 
                                      
                                 {parseFloat(data1?.totalSavingAmount)}
                            
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Savings(ETB)
                                  </span>
                                </div>
                              </div>
                            </div>

                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-flush h-xl-100">
                      <div className="container card-header pt-7" style={{display:"block"}}>
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Organisation Activity
                              </span>
                              <span className="text-gray-400 mt-1 fw-semibold fs-6">
                                Activity Of Organisation
                              </span>
                            </h3>
                          </div>

                          <div className="col-md-6 p-0">
                            <form >
                              <div className="d-flex align-items-center filterCss gap-1 w-100">
                                <div>
                                <select defaultValue={frequency} onChange={handlesorting} className="form-select graph-select" >
  <option value="day"> Day</option>
  <option value="week"> Week</option>
  <option value="month"> Monthly</option>
  <option value="year">Yearly</option>

</select>
                                </div>

                               
                              </div>
                            </form>
                          
                          </div>
                        </div>
                      </div>

                      <div className="bg-white overflow-auto">
                        <Chart
                          type="bar"
                          width={"100%"}
                          height={400}
                          
                          series={[
                            {
                              name: "User",
                              data: useractivedatayear,
                            },
                          ]}
                          
                          options={{
                            plotOptions: {
                              bar: {
                                borderRadius: 2,
                                columnWidth: "40%",
                              },
                              dataLabels: {
                                position: "Top",
                              },
                            },
                            title: {
                              //   text: "BarChar Developed by DevOps Team",
                              //   style: { fontSize: 30 },
                            },

                            subtitle: {
                              //   text: "This is BarChart Graph",
                              //   style: { fontSize: 18 },
                            },

                            colors: ["#3c7f8c"],
                            theme: { mode: "light" },

                            xaxis: {
                              tickPlacement: "on",
                              categories: useractivedatamonths,
                              //   title: {
                              //     text: "Year BY data",
                              //     style: { color: "##3c7f8c", fontSize: 10 },
                              //   },
                            },

                            yaxis: {
                              labels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                                style: { fontSize: "15", colors: ["#3c7f8c"] },
                              },
                              title: {
                                // text: "User In (K)",
                                // style: { color: "#f90000", fontSize: 15 },
                              },
                            },

                            legend: {
                              show: true,
                              position: "right",
                            },
                          
                            dataLabels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: {
                                colors: ["white"],
                                fontSize: 10,
                                position: "top",
                              },
                            },
                          }}
                        ></Chart>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Recent Data</h1>
                    </div>

                    {/* <div className="d-flex flex-column">
                      <div className="align-lg-items-center">
                        <form onSubmit={(e) => AddFormData(e)}>
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5 w-xxl-850px h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-4 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="name"
                                  placeholder="D-IDs"
                                />
                              </div>

                              <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  className="form-select border-0 flex-grow-1"
                                  data-control="select2"
                                  data-placeholder="Status"
                                  data-hide-search="true"
                                  name="category"
                                >
                                  <option value="" selected disabled>
                                    Status
                                  </option>
                                  <option value="pending">pending</option>
                                  <option value="Progress">Progress</option>
                                  <option value="Verified">Verified</option>
                                </select>
                              </div>
                            </div>

                            <div className="min-w-150px text-end">
                              <button
                                type="submit"
                                className="btn btn-dark"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={userlist}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Users
                      </span>
                      {/* <span className="text-muted mt-1 fw-semibold fs-7">
                        Over 50 Customer
                      </span> */}
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">D-ID ref No.</th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-100px">Date of Creation</th>
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {finduser.map((item) => (
                            <tr>
                              <td className="text-center">{item.fullname}</td>
                              <td className="text-center">{item.digitalrefID}</td>
                              <td className="text-center">
                                <span>{item.phone}</span>
                              </td>
                              <td className="text-center">{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                              <td className="text-center">
                                {item.status =="pending" ?
                                 <>
                                <span className="badge badge-light-pending text-danger fs-5">
                                  {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                </span>
                                 </>
                                 :
                                 <>
                                 <span className="badge badge-light-info text-success fs-5">
                                  {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                </span>
                                 </>}
                                
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-content-center flex-shrink-0">
                                  <button
                                    onClick={(e)=>custDetail(item._id)}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                   
                                    <span  className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                    
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/* <tr>

                                                        <td className="text-center">
                                                            Sunil Ganwane
                                                        </td>
                                                        <td className="text-center">ganwanesunil@gmail.com   </td>
                                                        <td className="text-center"><span>+211 123-456-7890 </span>
                                                        </td>
                                                        <td className="text-center">05/06/1998</td>
                                                        <td className="text-center"><span className="text-success">Verified</span></td>
                                                        <td className="text-center">
                                                           <div className="d-flex justify-content-center flex-shrink-0">
                                                                <a href="#" className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-kt-initialized="1">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye fs-4"></i>
                                                                    </span>

                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr><tr> */}

                          {/* <td className="text-center">
                                                            Jessica Chinara
                                                        </td>
                                                        <td className="text-center"> jessica@gmail.com </td>
                                                        <td className="text-center"><span>+251 123-456-7890</span>
                                                        </td>
                                                        <td className="text-center">05/06/1998</td>
                                                        <td className="text-center"><span className="text-success">Verified</span></td>
                                                        <td className="text-center">
                                                           <div className="d-flex justify-content-center flex-shrink-0">
                                                                <a href="#" className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-kt-initialized="1">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye fs-4"></i>
                                                                    </span>

                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Transactions
                      </span>
                    
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">
                              Transaction <br /> Date{" "}
                            </th>
                            <th className="min-w-150px">
                              PCN <br />
                              Number
                            </th>
                            <th className="min-w-150px">
                              Sender <br /> Name{" "}
                            </th>
                            <th className="min-w-100px">
                              Beneficiary <br /> Name{" "}
                            </th>
                            <th className="min-w-100px text-center">
                              Transaction <br /> Amount
                            </th>
                           
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {findTrans.map((item) => (
                            <tr>
                              <td className="text-center">{moment(item.transactionDate).format("DD/MM/YYYY")}</td>
                              <td className="text-center">{item.PCN} </td>
                              <td className="text-center">
                                <span>{item.senderName}</span>
                              </td>
                              <td className="text-center">{item.beneficiaryName}</td>

                              <td className="text-center">{item.sendingAmount}</td>
                             
                              <td className="text-center">
                                <span className="text-success">
                                  {item.status}
                                </span>
                              </td>
                              <td className="text-center">
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  <button
                                   onClick={(e)=>{transDetail(item._id)}}
                                    href="#"
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
